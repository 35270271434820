import React from 'react';
import { MainWidth } from '@components/MainLayout';
import iconPhone from './images/phone.png';
import iconEmail from './images/email.png';
import officialAccount1 from './images/image1.png';
import officialAccount2 from './images/image2.png';
import { Link } from 'react-router-dom';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

const recordText = {
  development: '鲁ICP备2021036348号-2',
  production: '鲁ICP备2021036348号-1',
  test: '',
};

const describe = [
  {
    title: '热门应用场景',
    links: [
      {
        label: '供应链金融',
        href: '/scenes/gyljr?prevMenu=scenes1',
      },
      {
        label: '跨境贸易',
        href: '/scenes/kjmy?prevMenu=scenes2',
      },
      {
        label: '智能制造',
        href: '/scenes/znzz?prevMenu=scenes3',
      },
      {
        label: '溯源管理',
        href: '/scenes/sygl?prevMenu=scenes4',
      },
    ],
  },
  {
    title: '热门产品',
    links: [
      {
        label: '分布式数字身份',
        href: '/product-center?currTabsKey=分布式数字身份&prevMenu=productCenter',
      },
      {
        label: '数字协同邮箱',
        href: '/product-center?currTabsKey=数字协同邮箱&prevMenu=productCenter',
      },
      {
        label: '数据保险箱',
        href: '/product-center?currTabsKey=数据保险箱&prevMenu=productCenter',
      },
      {
        label: '供应链贸易轨迹凭证',
        href: '/product-center?currTabsKey=供应链贸易轨迹凭证&prevMenu=productCenter',
      },
    ],
  },
  {
    title: '友情链接',
    links: [
      {
        label: '胶州城投',
        href: 'http://xchengtou.web.dongchengyun.cn/',
      },
      {
        label: '胶东供应链',
        href: 'https://www.linkingjz.com',
      },
      {
        label: '溪塔科技',
        href: 'https://www.rivtower.com',
      },
    ],
  },
];

export default function (props: any) {
  return (
    <div className={S.footerItem}>
      <MainWidth>
        <div className={S.mainWrap}>
          {describe.map(item => (
            <dl className={S.describe} key={item.title}>
              <dt>{item.title}</dt>
              {item.links.map(item => (
                <dd key={item.label}>
                  {item.href.includes('http') ? (
                    <a href={item.href} target="_blank" rel="noopener noreferrer">
                      {item.label}
                    </a>
                  ) : (
                    <Link to={item.href} target="_blank" rel="noopener noreferrer">
                      {item.label}
                    </Link>
                  )}
                </dd>
              ))}
            </dl>
          ))}

          <div className={S.asideWrap}>
            <div className={S.contactWrap}>
              <dl className={S.contactItem}>
                <dt>
                  <i className={S.iconWrap}>
                    <img src={iconPhone} alt="电话" />
                  </i>
                  <span>联系电话</span>
                </dt>
                <dd>
                  <a href="tel: 010-86467287">010-86467287</a>
                </dd>
                <dd>
                  <a href="tel: 0532-88983276">0532-88983276</a>
                </dd>
              </dl>
              <dl className={S.contactItem}>
                <dt>
                  <i className={S.iconWrap}>
                    <img src={iconEmail} alt="邮箱" />
                  </i>
                  <span>邮箱</span>
                </dt>
                <dd>
                  <a href="mailto:jdgongyinglian@linkingjz.com">jdgongyinglian@linkingjz.com</a>
                </dd>
              </dl>
            </div>

            <div className={S.scanCodeItem}>
              <img src={officialAccount1} alt="中国信通院" />
              {/* <p>中国信通院</p> */}
            </div>

            <div className={S.scanCodeItem}>
              <img src={officialAccount2} alt="星火.链网" />
              {/* <p>星火.链网</p> */}
            </div>
          </div>
        </div>

        <div className={S.copyright}>
          <span>©2021星火·链网骨干节点（胶州） 版权所有 |</span>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            rel="noopener noreferrer"
          >
            {recordText[process.env.NODE_ENV]}
          </a>
        </div>
      </MainWidth>
    </div>
  );
}
