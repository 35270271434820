import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Affix, Image } from 'antd';
import { MainWidth } from '@components/MainLayout';
import logo from './images/logo.svg';
import useUrlState from '@ahooksjs/use-url-state';

import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

const { SubMenu } = Menu;

// const linkArray = {
//   development: 'http://platform.jz-xinghuo.xyz/user/login/',
//   production: 'http://platform.jz-xinghuo.com/user/login/',
//   test:''
// };

export default function (props: any) {
  const [urlState, setUrlState] = useUrlState();
  const [currentMenu, setCurrentMenu] = useState(urlState.prevMenu || 'index');

  console.log('环境', process.env.NODE_ENV);

  const changeMenu = (e: any) => {
    // 这里需要在切换页面的时候清空除了prevMenu之外的参数，提升切换页面的时候的用户体验
    setUrlState({
      currTabsKey: null,
      prevMenu: e.key,
    });
  };
  const goPlatform = () => {
    let url = process.env.REACT_APP_PLATFORM_URL;
    window.open(url);
  };
  useEffect(() => {
    setCurrentMenu(urlState.prevMenu);
  }, [urlState.prevMenu]);

  // const toolsLink = linkArray[process.env.NODE_ENV];

  return (
    <Affix>
      <div className={S.header}>
        <MainWidth className={S.mainWidth}>
          {/* <Link to="/"> */}
          <div className={S.logoWrap}>
            <Image src={logo} preview={false} className={S.logoImg} />
            <span className={S.title}>骨干节点(胶州)</span>
          </div>
          {/* </Link> */}

          <Menu
            onClick={changeMenu}
            selectedKeys={[currentMenu]}
            mode="horizontal"
            className={S.menuItem}
          >
            <Menu.Item key="index">
              <Link to="/">首页</Link>
            </Menu.Item>

            <SubMenu key="scenes" title="应用场景">
              <Menu.Item key="scenes1">
                <Link to="/scenes/gyljr">供应链金融</Link>
              </Menu.Item>

              <Menu.Item key="scenes2">
                <Link to="/scenes/kjmy">跨境贸易</Link>
              </Menu.Item>

              <Menu.Item key="scenes3">
                <Link to="/scenes/znzz">智能制造</Link>
              </Menu.Item>

              <Menu.Item key="scenes4">
                <Link to="/scenes/sygl">溯源管理</Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="productCenter">
              <Link to="/product-center">产品中心</Link>
            </Menu.Item>

            <Menu.Item key="typicalCases">
              <Link to="/typical-cases">新闻动态</Link>
            </Menu.Item>

            <Menu.Item key="aboutUs">
              <Link to="/about-us">关于我们</Link>
            </Menu.Item>
          </Menu>

          <div className={S.tools}>
            {/* {toolsLink.includes('http') ? (
              // eslint-disable-next-line
              <a href={toolsLink} target="_blank">
                控制台
              </a>
            ) : (
              <Link to={toolsLink}>控制台</Link>
            )} */}
            <span onClick={goPlatform} style={{ cursor: 'pointer' }}>
              控制台
            </span>
          </div>
        </MainWidth>
      </div>
    </Affix>
  );
}
